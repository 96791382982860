<template>
<nav class="download-about">
  <p class="download-about__summary">
    {{$t('company.artBonbon.introductionPDF')}}
  </p>
  <div class="context">
    <ButtonRoundLine class="context__button" @click="onClickButton('ko')">
      <span>{{$t('company.language.ko')}}</span>
      <Icon icon-name="download"/>
    </ButtonRoundLine>
    <ButtonRoundLine class="context__button" @click="onClickButton('en')">
      <span>{{$t('company.language.en')}}</span>
      <Icon icon-name="download"/>
    </ButtonRoundLine>
  </div>
</nav>
</template>

<script setup>
import ButtonRoundLine from '~/components/button/round-line.vue'
import Icon from '~/components/icon/index.vue'

function onClickButton(code)
{
  try
  {
    let downloadLink = document.createElement('a')
    switch (code)
    {
      case 'ko':
      default:
        downloadLink.href = '/resource/i-Scream_arts_Introduction_KOR.pdf'
        break
      case 'en':
        downloadLink.href = '/resource/i-Scream_arts_Introduction_ENG.pdf'
        break
    }
    downloadLink.download = downloadLink.href.split('/')[1]
    downloadLink.click()
  }
  catch (_)
  {
    alert(t('base.doitLater'))
  }
}
</script>

<style lang="scss" scoped>
@use '../../../assets/scss/mixins';
.download-about {
  &__summary {
    display: block;
    margin: 0;
    text-align: center;
    line-height: 1.05;
    font-size: 15px;
    font-weight: 700;
  }
  @include mixins.responsive(tablet) {
    &__summary {
      text-align: right;
      font-size: 18px;
    }
  }
}
.context {
  margin: 12px 0 0;
  display: flex;
  justify-content: center;
  gap: 0 12px;
  &__button {
    width: 100%;
    height: 60px;
    padding: 0;
    min-width: unset;
  }
  @include mixins.responsive(tablet) {
    display: flex;
    justify-content: flex-end;
    gap: 0 12px;
    &__button {
      padding: 0 32px;
    }
  }
}
</style>
