<template>
<button type="button" class="button-round">
  <span class="button-round__wrap">
    <slot/>
  </span>
</button>
</template>

<style lang="scss" scoped>
@use '../../assets/scss/mixins';
.button-round {
  margin: 0;
  padding: 0 30px;
  height: 60px;
  box-sizing: border-box;
  border-radius: 30px;
  background: none;
  border: 1px solid var(--color-base);
  @include mixins.button-extends();
  @include mixins.mouse-event(active) {
    opacity: .5;
  }
  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 6px;
    font-size: 14px;
    line-height: 1.15;
    font-weight: 500;
    white-space: nowrap;
    ::v-deep(em) {
      font-style: normal;
    }
    ::v-deep(svg) {
      --icon-size: 14px;
      --icon-color: var(--color-base);
    }
  }
  @include mixins.responsive(tablet) {
    min-width: 180px;
    &__wrap {
      font-size: 16px;
    }
  }
}
</style>
