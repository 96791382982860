<template>
<canvas
  ref="$root"
  :width="width"
  :height="height"
  :class="[ show && 'show']"/>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const $root = ref()
const show = ref(false)
const width = ref(0)
const height = ref(0)
let pause = false
let ctx
let particles = []
let totalParticles = 11
let maxRadius = 900
let minRadius = 400
const colors = [
  {r: 0,  g: 34, b: 203}, // blue
  {r: 227, g: 24, b: 28}, // red
  {r: 187, g: 57, b: 86}, // red
  {r: 81, g: 34, b: 108}, // purple
  {r: 53, g: 154, b: 250}, // skyblue
  {r: 216, g: 255, b: 0}, // skyblue
]
const PI2 = Math.PI * 2
let requestAnimation
let resizeTimer

function resizePlay()
{
  width.value = window.innerWidth
  height.value = window.innerHeight
  let max = width.value
  if (height.value > width.value) max = height.value
  maxRadius = max / 1.6
  minRadius = max / 2
  ctx.globalCompositeOperation = 'saturation'
  createParticles()
}

function onResize()
{
  if (resizeTimer) clearTimeout(resizeTimer)
  resizeTimer = setTimeout(() => resizePlay(), 500)
}

function createParticles()
{
  let curColor = 0
  particles = []
  for (let i = 0; i < totalParticles; i++) {
    const item = new GlowParticle(
      Math.random() * width.value,
      Math.random() * height.value,
      Math.random() *
      (maxRadius - minRadius) + minRadius,
      colors[curColor]
    )
    if (++curColor >= colors.length) curColor = 0
    particles[i] = item
  }
}

function animate()
{
  requestAnimation = window.requestAnimationFrame(animate)
  if (!ctx) return
  ctx.clearRect(0, 0, width.value, height.value)
  for (let i = 0; i < totalParticles; i++) {
    const item = particles[i]
    item.animate()
  }
}

function onPause()
{
  if (pause) return
  pause = true
  window.cancelAnimationFrame(requestAnimation)
}

function onPlay()
{
  if (!pause) return
  requestAnimation = window.requestAnimationFrame(animate)
  pause = false
}

class GlowParticle {
  constructor(x, y, radius, rgb) {
    this.x = x
    this.y = y
    this.radius = radius
    this.rgb = rgb
    this.vx = Math.random() * 4
    this.vy = Math.random() * 4
  }
  animate() {
    this.x += this.vx
    this.y += this.vy

    if (this.x < 0) {
      this.vx *= -1
      this.x += 10
    } else if (this.x > width.value) {
      this.vx *= -1
      this.x -= 10
    }

    if (this.y < 0) {
      this.vy *= -1
      this.y += 10
    } else if (this.y > height.value) {
      this.vy *= -1
      this.y -= 10
    }

    ctx.beginPath()
    const g = ctx.createRadialGradient(
      this.x,
      this.y,
      this.radius * 0.001,
      this.x,
      this.y,
      this.radius
    )
    g.addColorStop(0, `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, 1)`)
    g.addColorStop(1, `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, 0)`)
    ctx.fillStyle = g
    ctx.arc(this.x, this.y, this.radius, 0, PI2, false)
    ctx.fill()
  }
}

onMounted(async () => {
  ctx = $root.value.getContext('2d')
  window.addEventListener('resize', onResize, false)
  if (window.DeviceOrientationEvent) {
    window.addEventListener('orientationchange', onResize, false)
  }
  resizePlay()
  requestAnimation = window.requestAnimationFrame(animate)
  show.value = true
  pause = false
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize, false)
  window.removeEventListener('orientationchange', onResize, false)
  window.cancelAnimationFrame(requestAnimation)
  ctx = null
  pause = true
})

defineExpose({
  pause: onPause,
  play: onPlay,
})
</script>

<style lang="scss" scoped>
canvas {
  width: 100%;
  height: 100%;
  background-color: #02052e;
  opacity: 0;
  box-sizing: border-box;
  transition: opacity 400ms ease-out;
  &.show {
    opacity: 1;
  }
}
</style>
