<template>
<article class="introduce">
  <article ref="$jumbotron" class="jumbotron">
    <Background ref="$background" class="jumbotron__background"/>
    <div class="jumbotron__body">
      <figure class="jumbotron__image">
        <picture>
          <source
            srcset="/images/company/img-jumbotron-desktop.png, /images/company/img-jumbotron-desktop@2x.png 2x"
            media="all and (min-width: 1280px)"/>
          <source
            srcset="/images/company/img-jumbotron-tablet.png, /images/company/img-jumbotron-tablet@2x.png 2x"
            media="all and (min-width: 900px)"/>
          <source
            srcset="/images/company/img-jumbotron-mobile.png, /images/company/img-jumbotron-mobile@2x.png 2x"/>
          <img
            src="/images/company/img-jumbotron-mobile.png"
            alt="Curious about everything">
        </picture>
      </figure>
      <p class="jumbotron__description">
        <strong>{{$t('base.pabloarts')}}</strong>{{$t('company.jumbotron.introduce1')}}<br/>
        {{$t('company.jumbotron.introduce2')}}
      </p>
    </div>
    <nav class="jumbotron__next-section">
      <button
        type="button"
        :title="$t('company.jumbotron.nextSection')"
        @click="onClickGotoPhilosophy">
        <img src="~/assets/images/company/ico-next-section.svg" draggable="false" alt="">
      </button>
    </nav>
  </article>

  <section ref="$philosophy" class="philosophy">
    <header class="philosophy__header">
      <p class="section-header-description">{{$t('company.philosophy.headDescription')}}</p>
      <h1 class="section-header-title">{{$t('company.philosophy.headTitle')}}</h1>
    </header>
    <div class="philosophy__body">
      <p v-html="$t('company.philosophy.description')" class="philosophy__description"/>
      <div class="philosophy__blockquote">
        <blockquote>
          <div class="wrap">
            <p class="message">
              <Picture
                src="/images/company/philosophy-002.png"
                alt="Every child is an artist. The problem is how to remain an artist. once he grows up."/>
            </p>
            <Picture
              src="/images/company/philosophy-003.png"
              alt="Pablo picasso"
              class="sign"/>
          </div>
        </blockquote>
        <figure class="photo">
          <Picture src="/images/company/philosophy-001.png"/>
        </figure>
      </div>
    </div>
  </section>

  <section class="question">
    <p id='question-for-desktop' v-html="$t('company.question')" class="question__description"/>
    <p id='question-for-mobile' v-html="$t('company.questionMobile')" class="question__description"/>
  </section>

  <section class="motivation">
    <header class="motivation__header">
      <p class="section-header-description">
        {{$t('company.motivation.headDescription')}}
      </p>
      <h1 class="section-header-title">
        {{$t('company.motivation.headTitle')}}
      </h1>
      <button type="button" @click="visibleResourceText = true">
        <span>
          <em>{{$t('company.motivation.resource')}}</em>
          <Icon icon-name="arrow-right"/>
        </span>
      </button>
    </header>
    <div class="motivation__body">
      <p v-html="$t('company.motivation.description')" class="motivation__description"/>
      <article class="digital-age">
        <figure>
          <Picture src="/images/company/motivation-001.png"/>
        </figure>
        <h1 v-html="$t('company.motivation.sectionTitle')" class="section-header-title"/>
        <p>{{$t('company.motivation.sectionDescription')}}</p>
      </article>
    </div>
  </section>

  <section class="competence">
    <header class="competence__header">
      <p class="section-header-description">
        {{$t('company.competence.headDescription')}}
      </p>
      <h1 class="section-header-title">
        {{$t('company.competence.headTitle')}}
      </h1>
    </header>
    <div class="scroll-container competence__body">
      <div class="competence__cards">
        <div class="scroll-container__item competence__card">
          <div>
            <header>
              <em>01</em>
              <h2>{{$t('company.competence.section1.title')}}</h2>
            </header>
            <ul>
              <li v-for="(_,key) in new Array(7)">
                <span>{{$t(`company.competence.section1.word${key+1}`)}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="scroll-container__item competence__card">
          <div>
            <header>
              <em>02</em>
              <h2>{{$t('company.competence.section2.title')}}</h2>
            </header>
            <ul>
              <li v-for="(_,key) in new Array(4)">
                <span>{{$t(`company.competence.section2.word${key+1}`)}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="scroll-container__item competence__card">
          <div>
            <header>
              <em>03</em>
              <h2>{{$t('company.competence.section3.title')}}</h2>
            </header>
            <ul>
              <li v-for="(_,key) in new Array(4)">
                <span>{{$t(`company.competence.section3.word${key+1}`)}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="art-bonbon">
    <header class="art-bonbon__header">
      <h2 v-html="$t('company.artBonbon.title')" class="section-header-title"/>
      <nav>
        <ButtonRoundLine @click="onClickGotoBonbonArt">
          <em>{{$t('company.artBonbon.readMore')}}</em>
          <Icon icon-name="arrow-right"/>
        </ButtonRoundLine>
        <ButtonRoundLine class="link" @click="onClickGotoAppStore">
          <em>{{$t('company.artBonbon.freeTrial')}}</em>
        </ButtonRoundLine>
        <DownloadAbout/>
      </nav>
    </header>
    <div class="art-bonbon__body">
      <figure>
        <Picture src="/images/company/art-bonbon-001.png" alt=""/>
      </figure>
      <p v-html="$t('company.artBonbon.description')"/>
      <nav>
        <ButtonRoundLine class="button">
          <em>{{$t('company.artBonbon.readMore')}}</em>
          <Icon icon-name="arrow-right"/>
        </ButtonRoundLine>
        <ButtonRoundLine class="link" @click="onClickGotoAppStore">
          <em>{{$t('company.artBonbon.freeTrial')}}</em>
        </ButtonRoundLine>
        <DownloadAbout/>
      </nav>
    </div>
  </section>

  <section class="curiosity">
    <h1 class="section-header-title curiosity__title">
      {{$t('company.curiosity.title')}}
    </h1>
    <figure class="curiosity__photo">
      <Picture src="/images/company/curiosity-001.png"/>
    </figure>
    <div class="curiosity__description">
      <p
        v-html="$t('company.curiosity.description')"
        :class="[ curiosityVisibleDescription && 'visible' ]"/>
      <button
        v-if="!curiosityVisibleDescription"
        type="button"
        @click="curiosityVisibleDescription = !curiosityVisibleDescription">
        <span>
          <em>{{$t('company.curiosity.readMore')}}</em>
          <Icon icon-name="arrow-down"/>
        </span>
      </button>
    </div>
  </section>

  <section class="press">
    <h1 class="section-header-title press__title">
      {{$t('company.press.title')}}
    </h1>
    <ul v-if="press.items?.length > 0" class="press__index">
      <li v-for="o in press.currentItems">
        <PressItem :link="o.link" :date="o.date" :title="o.title"/>
      </li>
    </ul>
    <p v-else class="press__empty">
      {{$t('company.press.empty')}}
    </p>
    <nav v-if="press.remain" class="press__more">
      <ButtonRoundLine @click="press.more()">
        <em>{{$t('company.press.more')}}</em>
      </ButtonRoundLine>
    </nav>
  </section>

  <client-only>
    <teleport to="body">
      <transition name="modal-fade">
        <ModalWrap
          v-if="visibleResourceText"
          :use-hidden="true"
          @close="visibleResourceText = false">
          <ResourceText @close="visibleResourceText = false"/>
        </ModalWrap>
      </transition>
    </teleport>
  </client-only>
</article>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { headerHeight } from '~/libs/consts.js'
import { newLink, sleep } from '~/libs/util.js'
import { navigationStore } from '~/store/navigation.js'
import { layoutStore } from '~/store/layout.js'
import { pressStore } from '~/store/company.js'
import Picture from '~/components/assets/custom-picture.vue'
import ButtonRoundLine from '~/components/button/round-line.vue'
import Icon from '~/components/icon/index.vue'
import PressItem from '~/components/pages/company/press-item.vue'
import Background from '~/components/pages/company/background.vue'
import { ModalWrap } from '~/components/modal/index.js'
import ResourceText from '~/components/pages/company/resource-text.vue'
import DownloadAbout from '~/components/pages/company/download-about.vue'

const { t } = useI18n()
const $philosophy = ref()
const $jumbotron = ref()
const $background = ref()
const layout = layoutStore()
const navigation = navigationStore()
const press = pressStore()
const curiosityVisibleDescription = ref(false)
const visibleResourceText = ref(false)
let io

definePageMeta({
  middleware: 'service',
})

// set intersection
if (process.client)
{
  io = new IntersectionObserver(entries => updateJumbotron(entries[0]?.isIntersecting), {
    rootMargin: `-${headerHeight * .5}px 0px 0px 0px`,
  })
}

function updateJumbotron(sw)
{
  layout.headerTheme = sw ? 'light' : 'dark'
  if (sw)
  {
    $background.value.play()
  }
  else
  {
    $background.value.pause()
  }
}

function onClickGotoBonbonArt()
{
  newLink(navigation.bonbonArt)
}

function onClickGotoAppStore()
{
  newLink(navigation.appStore)
}

async function onClickGotoPhilosophy()
{
  const $html = document.querySelector('html')
  const top = $philosophy.value.offsetTop
  $html.classList.add('mode-smooth')
  await sleep(20)
  window.scrollTo(0, top)
  $html.classList.remove('mode-smooth')
}

onMounted(() => {
  if (io) io.observe($jumbotron.value)
})
onBeforeUnmount(() => {
  if (io) io.unobserve($jumbotron.value)
  press.reset()
})
</script>

<style src="./index.scss" lang="scss" scoped></style>
