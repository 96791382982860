<template>
<div class="press-item">
  <div class="press-item__body">
    <span v-if="date">{{date}}</span>
    <p>{{props.title}}</p>
  </div>
  <a :href="props.link" target="_blank" class="press-item__link">
    <i><Icon icon-name="arrow-right"/></i>
  </a>
</div>
</template>

<script setup>
import { computed } from 'vue'
import Icon from '~/components/icon/index.vue'

const props = defineProps({
  link: String,
  date: String,
  title: String,
})
const date = computed(() => {
  if (!props.date) return ''
  return props.date.split('-').join('. ')
})
</script>

<style src="./press-item.scss" lang="scss" scoped></style>
